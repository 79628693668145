import GalleryItem from "./GalleryItem";
const images = [
  {
    id: 1,
    src: "https://images.pexels.com/photos/18256097/pexels-photo-18256097.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Premium Cotton Fabrics",
    category: "Cotton",
  },
  {
    id: 2,
    src: "https://images.pexels.com/photos/5698908/pexels-photo-5698908.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Caps & Hats Collection",
    category: "Accessories",
  },
  {
    id: 3,
    src: "https://images.pexels.com/photos/8217415/pexels-photo-8217415.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Cozy Sweatshirts Collection",
    category: "Sweatshirts",
  },
  {
    id: 4,
    src: "https://images.pexels.com/photos/7055916/pexels-photo-7055916.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    title: "Baby Wear Collection",
    category: "Baby Wear",
  },
];

const Gallery = () => {
  return (
    <section className="gallery">
      <h2 className="section-title">Gallery</h2>
      <div className="gallery-wrapper">
        {images.map((image) => (
          <GalleryItem key={image.id} image={image} />
        ))}
      </div>
    </section>
  );
};

export default Gallery;
