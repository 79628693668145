import { useRef } from "react";
import { useGsapFooterHeadline } from "../hooks/gsap";

const Footer = () => {
  const footerRef = useRef(null);
  const footerHeadlineRef = useRef(null);

  useGsapFooterHeadline(footerHeadlineRef, footerRef);
  return (
    <section className="footer wrapper" ref={footerRef}>
    <h1 ref={footerHeadlineRef}>Bonjour</h1>
    <p>
      &copy;{new Date().getFullYear()} Gebreid. Crafted by&nbsp;
      <a 
        href="https://nediantechnologies.com" 
        target="_blank" 
        rel="noopener noreferrer" 
        style={{ color: 'var(--color-darker)' }}
      >
        nt
      </a>
    </p>
    <h6>Based in Karachi, Pakistan</h6>
  </section>
  
  
  );
};

export default Footer;
