import { useRef } from "react";
import { useGsapNotFoundHeadline } from "../hooks/gsap"; // You can use a custom hook or replace with your own animations

const PrivacyPolicy = ({ needFullHeight }) => {
  const headlineRef = useRef(null);

  useGsapNotFoundHeadline(headlineRef, 1.4); // You can customize this hook to animate the headline if needed

  return (
    <section className={`privacy-policy wrapper ${needFullHeight && "min-h-100vh"}`}>
      <h2 className="section-title">Privacy Policy</h2>
      <div className="headline-1" ref={headlineRef}   style={{ marginBottom: "20px" }} >
        Your privacy is important to us
      </div>
      <div className="privacy-content">
        <h3>1. Information We Collect</h3>
        <p>
          We may collect and process the following types of information:
        </p>
        <ul>
          <li><strong>Personal Information:</strong> This includes information you provide directly, such as your name, email address, phone number, etc.</li>
          <li><strong>Usage Data:</strong> Information about how you interact with our website and services, including browsing data.</li>
          <li><strong>Cookies:</strong> We use cookies and similar technologies to enhance your experience.</li>
        </ul>

        <h3>2. How We Use Your Information</h3>
        <p>We use the information for the following purposes:</p>
        <ul>
          <li>To provide services, manage accounts, and assist with customer support.</li>
          <li>To improve our website and services based on user behavior.</li>
          <li>To send promotional communications and updates with your consent.</li>
        </ul>

        <h3>3. Sharing Your Information</h3>
        <p>We do not sell your personal data. We may share information with trusted third parties who assist with business operations.</p>

        <h3>4. Security of Your Information</h3>
        <p>We implement measures to protect your data but cannot guarantee 100% security.</p>

        <h3>5. Your Privacy Choices</h3>
        <ul>
          <li>Access and correct your personal information anytime.</li>
          <li>Opt out of marketing communications.</li>
          <li>Adjust your browser settings to manage cookies.</li>
        </ul>

        <h3>6. Changes to This Privacy Policy</h3>
        <p>We may update this policy and notify you of significant changes.</p>

        <h3>7. Contact Us</h3>
        <p>If you have questions, contact us at:</p>
        <p><strong>Email: </strong>gebreidofficial@gmail.com</p>
        <p><strong>Address: </strong>Karachi, Pakistan</p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
