import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

function ContactForm() {
  const [state, handleSubmit] = useForm("xnnaqrad");

  if (state.succeeded) {
    return <p>Thanks for contacting us! We will contact you soon 😊</p>;
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        maxWidth: '400px',
        margin: '0 auto',
        padding: '20px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        borderRadius: '5px',
        backgroundColor: 'var(--color-lighter)',
      }}
    >
      <div style={{ marginBottom: '1rem' }}>
        <label
          htmlFor="name"
          style={{
            display: 'block',
            marginBottom: '0.5rem',
            color: 'var(--color-darker)',
          }}
        >
          Name:
        </label>
        <input
          type="text"
          id="name"
          name="name"
          required
          style={{
            width: '100%',
            padding: '8px',
            fontSize: '1.2rem',
            borderRadius: '3px',
            border: '1px solid #ccc',
          }}
        />
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <label
          htmlFor="email"
          style={{
            display: 'block',
            marginBottom: '0.5rem',
            color: 'var(--color-darker)',
          }}
        >
          Email:
        </label>
        <input
          type="email"
          id="email"
          name="email"
          required
          style={{
            width: '100%',
            padding: '8px',
            fontSize: '1.2rem',
            borderRadius: '3px',
            border: '1px solid #ccc',
          }}
        />
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
          style={{ color: 'red', fontSize: '0.8rem', marginTop: '0.2rem' }}
        />
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <label
          htmlFor="message"
          style={{
            display: 'block',
            marginBottom: '0.5rem',
            color: 'var(--color-darker)',
          }}
        >
          Message:
        </label>
        <textarea
  id="message"
  name="message"
  rows="4"
  required
  style={{
    maxWidth: '100%', // Adjust max-width as needed
    width: '100%',    // Ensure it takes full width when not resizing
    padding: '8px',
    fontSize: '1.2rem',
    borderRadius: '3px',
    border: '1px solid #ccc',
    boxSizing: 'border-box', // Ensure padding and border are included in width
    resize: 'vertical',      // Allow vertical resizing only
  }}
></textarea>

        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
          style={{ color: 'red', fontSize: '1.2rem', marginTop: '0.2rem' }}
        />
      </div>
      <button
        type="submit"
        disabled={state.submitting}
        style={{
          backgroundColor: 'var(--color-primary)',
          color: 'var(--color-lighter)',
          padding: '10px 20px',
          fontSize: '1rem',
          borderRadius: '3px',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        {state.submitting ? (
          <>
            <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '0.5rem' }} spin /> Sending...
          </>
        ) : (
          <>
            {' '}
            Send <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '0.5rem' }} />
          </>
        )}
      </button>
    </form>
  );
}

export default ContactForm;
