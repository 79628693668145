const About = () => {
  return (
    <section className="about wrapper">
      <h2 className="section-title">About</h2>
      <p>  In the heart of our bustling city, amidst the hum of sewing machines and the scent of freshly woven threads, Gebreid stands as a beacon of craftsmanship and quality. For over seven decades, we've meticulously woven dreams into reality, specializing in the art of fabric manufacturing and textile consultancy.</p>

      <p> From the classic allure of fine silks to the robust comfort of durable linens, each fabric tells a story of tradition and innovation. Our seasoned artisans, guided by decades of expertise, meticulously craft textiles that adorn homes, elevate fashion, and inspire industries.</p>
    </section>
  );
};

export default About;
